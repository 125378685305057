import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'
import './PressCard.css'
import Accordion from './Accordion3'
import Content from './Content'


const MythFactCard = ({
  topic,
  claim,
  facts,
  close,
  slug,
  categories = [],
  className = '',
  frontmatter,
  ...props
}) => (
  <div className={`PressCard ${className}`}>
    <div className="MythFactCard--Content">
      {topic && <h3><Content className="MythFactCard--Topic" source={topic}></Content></h3>}
      {claim && <h3 className="MythFactCard--Title"><b>CLAIM: </b>{claim}</h3>}
      <Accordion items={facts} />
      {close && <b><Content className="MythFactCard--Topic" source={close}></Content></b>}
    </div>
  </div>
)

export default MythFactCard
