import React from 'react'
import _kebabCase from 'lodash/kebabCase'
import './Accordion.css'
import Content from './Content'

export default class Accordion extends React.Component {
  static defaultProps = {
    items: [],
    className: ''
  }

  state = {
    activeItem: null
  }

  handleClick = index => {
    this.setState({
      activeItem: this.state.activeItem === index ? null : index
    })
  }

  render() {
    const { items, className } = this.props
    return (
      <div className={`Accordion ${className}`}>
        {!!items &&
          items.map((item, index) => (
            <div
              className={`Accordion2--item`}
              key={`accordion2-item-${_kebabCase(item.fact) + '-' + index}`}
            >
              <h2 className="flex">
                <b>FACT:  </b><span>{item.fact}</span>

                {!!item.proofs &&
                  item.proofs.map((prf, index) => (
                    <div
                      className={`Accordion2--item`}
                      key={`accordion2-item-${_kebabCase(prf.proof) + '-' + index}`}
                    >
                      <b>PROOF: </b><h2 className="flex">
                        <Content source={prf.proof}></Content>
                      </h2>
                    </div>
                  ))}

              </h2>
            </div>
          ))}
      </div>
    )
  }
}
